import {TypographyStyled500} from '@/components/faqs/StyledFAQ';
import {getCfdiValidation} from '@/components/orderSimply/utils/cfdiValidations';
import {Box} from '@mui/system';
import React from 'react';
import {useTranslation} from 'react-i18next';

type ValidationErrorProps = {
    errorInfo: ErrorInfo;
};

export interface ErrorInfo {
    poReferenceId: number;
    entrySheets: EntrySheet[];
}

export interface EntrySheet {
    entrySheet: number;
    errors: any[];
}

const ValidationErrorMassive: React.FC<ValidationErrorProps> = ({errorInfo}) => {
    const {t} = useTranslation();
    const {poReferenceId, entrySheets} = errorInfo;
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                minHeight: '20px',
                padding: '10px',
                borderBottom: '1px solid grey',
            }}
        >
            <TypographyStyled500 sx={{marginLeft: '10px', minWidth: '180px'}}>
                <span> {poReferenceId}</span>
            </TypographyStyled500>
            <TypographyStyled500
                sx={{
                    marginLeft: '10px',
                    minWidth: '180px',
                    flexDirection: 'column',
                    gap: '20px',
                    width: '100%',
                }}
            >
                {entrySheets.map((item) => (
                    <div
                        key={item.entrySheet}
                        style={{
                            display: 'flex',
                            borderBottom: '1px solid 	#E8E8E8',
                            marginBottom: '14px',
                            width: '100%',
                        }}
                    >
                        {item.entrySheet}
                        <TypographyStyled500
                            sx={{
                                marginLeft: '100px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '20px',
                            }}
                        >
                            {entrySheets
                                .filter((it) => it.entrySheet === item.entrySheet)
                                .map((item) =>
                                    item.errors.map((error, index) => {
                                        const errorInfo = getCfdiValidation(error);
                                        return (
                                            <span key={index}>
                                                ○{' '}
                                                {t(errorInfo.description) ?? errorInfo.description}
                                            </span>
                                        );
                                    })
                                )}
                        </TypographyStyled500>
                    </div>
                ))}
            </TypographyStyled500>
        </Box>
    );
};

export default ValidationErrorMassive;
