import {TextError, TextSuccess} from '@/components/UI/molecules/textButton/textButtons';
import {fetchPutStatusOrderConfirm} from '@components/orderConfirm/redux/actions/OrderConfirmActions';
import {ButtonCancel, Loader, Nav, SaveButton} from '@components/faqs/StyledFAQ';
import {AppDispatch, RootState} from '@config/store';
import {Box, FormControl, InputLabel, MenuItem, Modal, Select, Typography} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {DateTime} from 'luxon';
export const StyledModal = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 85vw;
    border-radius: 10px;
    padding-left: 60px;
    padding-right: 50px;
`;

interface IOrderConfirmStatusModalProps {
    isModalOpen: boolean;
    closeModal: any;
    order: any;
    action: string;
}

const OrderConfirmStatusModal = (props: IOrderConfirmStatusModalProps) => {
    const {t} = useTranslation();
    const {isModalOpen, closeModal, action, order} = props;
    const dispatch: AppDispatch = useDispatch();
    const theme = useTheme();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const loading = useSelector((state: RootState) => state.rolesManager.loading);
    const [rejectedReason, setRejectedReason] = useState<string>('');
    const updateInvoiceStatus = () => {
        if (token && order) {
            dispatch(
                fetchPutStatusOrderConfirm(
                    token,
                    order,
                    action,
                    rejectedReason !== '' ? rejectedReason : undefined
                )
            );
        }
        closeModal();
    };

    const handleChangeReject = (event: any) => {
        setRejectedReason(event.target.value);
    };

    useEffect(() => {
        setRejectedReason('');
    }, [isModalOpen]);

    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            marginLeft: '10px',
                        }}
                    >
                        {action === 'INVOICE_IN_PROCESS'
                            ? t('confirm_invoice')
                            : t('reject_invoice')}
                    </Typography>
                </Nav>
                <Box sx={{padding: '20px'}}>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                        }}
                    >
                        <span style={{color: 'gray'}}>
                            {t('consultpo.table.headers.purchase_order')}:{' '}
                            <span style={{color: 'black'}}>{order ? order.orderId : ''}</span>
                        </span>
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                            marginTop: '10px',
                        }}
                    >
                        <span style={{color: 'gray'}}>
                            {t('consultpo.table.headers.posting_date')}:{' '}
                            <span style={{color: 'black'}}>
                                {order
                                    ? DateTime.fromJSDate(new Date(order.invoiceDate)).toFormat(
                                          'dd-MM-yyyy'
                                      )
                                    : ''}
                            </span>
                        </span>
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                            marginTop: '10px',
                        }}
                    >
                        <span style={{color: 'gray'}}>
                            {t('consultpo.table.headers.invoice_reference')}:{' '}
                            <span style={{color: 'black'}}>
                                {order ? order.invoiceReference : ''}
                            </span>
                        </span>
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 600,
                            marginLeft: '10px',
                            marginTop: '20px',
                        }}
                    >
                        <Typography>
                            {t('change_status_to')}:{' '}
                            {action === 'INVOICE_IN_PROCESS' ? (
                                <TextSuccess>{t('candidates.approved').toUpperCase()}</TextSuccess>
                            ) : (
                                <TextError>{t('candidates.rejected').toUpperCase()}</TextError>
                            )}{' '}
                        </Typography>
                    </Typography>
                </Box>
                {action !== 'INVOICE_IN_PROCESS' && (
                    <Box sx={{padding: '20px'}}>
                        <FormControl sx={{width: '400px'}}>
                            <InputLabel id="demo-simple-select-label">
                                {t('partial_conf_po.core.reason_rejection')}
                            </InputLabel>

                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={rejectedReason}
                                label={t('reason_rejection')}
                                onChange={(event) => handleChangeReject(event)}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 100,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value={'Incomplete or Illegible Invoice'}>
                                    {t('order_comfirm.IncompleteOrIllegibleInvoice')}
                                </MenuItem>
                                <MenuItem value={'Invoice missing Company RFC'}>
                                    {t('order_comfirm.InvoiceMissingCompanyRFC')}
                                </MenuItem>
                                <MenuItem value={'Invoice from Previous Years'}>
                                    {t('order_comfirm.InvoiceFromPreviousYears')}
                                </MenuItem>
                                <MenuItem value={'Invoice in Different Currency vs Purchase Order'}>
                                    {t('order_comfirm.InvoiceInDifferentCurrency')}
                                </MenuItem>
                                <MenuItem value={'Invoice for Different Company vs Purchase Order'}>
                                    {t('order_comfirm.InvoiceForDifferentCompany')}
                                </MenuItem>
                                <MenuItem value={'Invoice to Different Supplier vs Purchase Order'}>
                                    {t('order_comfirm.InvoiceToDifferentSupplier')}
                                </MenuItem>
                                <MenuItem value={'Price Discrepancy'}>
                                    {t('order_comfirm.PriceDiscrepancy')}
                                </MenuItem>
                                <MenuItem value={'Invoice without Goods Receipt'}>
                                    {t('order_comfirm.InvoiceWithoutGoodsReceipt')}
                                </MenuItem>
                                <MenuItem value={'Duplicate Invoice'}>
                                    {t('order_comfirm.DuplicateInvoice')}
                                </MenuItem>
                                <MenuItem value={'Other reason, consult your buyer'}>
                                    {t('order_comfirm.OtherReasonConsultBuyer')}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        height: '56px',
                        alignItems: 'center',
                        margin: '20px',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ButtonCancel onClick={closeModal}> {t('cancel')}</ButtonCancel>
                    <SaveButton
                        disabled={
                            loading || (action !== 'INVOICE_IN_PROCESS' && rejectedReason === '')
                        }
                        onClick={updateInvoiceStatus}
                    >
                        {loading ? <Loader size={24} /> : t('apply').toUpperCase()}
                    </SaveButton>
                </Box>
            </Box>
        </StyledModal>
    );
};

export default OrderConfirmStatusModal;
