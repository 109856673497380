import {
    Box,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    TextField,
    Typography,
} from '@mui/material';
import React, {useEffect} from 'react';
import logoAbi from '@order/images/logoAbi.svg';
import Loader from '@components/UI/molecules/loading/Loading';
import {useIsMobile} from '@hooks/useIsMobile';
import {useFormatNumber} from '@hooks/useFormatNumber';
import {useParams} from 'react-router-dom';
import {useCalculateReceivedGenesis} from '@hooks/useCalculateReceived';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller} from 'react-hook-form';
import iconArrowUp from '@order/images/iconArrowUp.svg';
import iconArrowDown from '@order/images/iconArrowDown.svg';
import {MenuItem, Select} from '@mui/material';
import {
    fetchPostOrderGenesis,
    resetOrderIncompleteSuccessGenesis,
} from '@components/orderGenesis/redux/actions/OrderActions';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@config/store';
import {BackButton, OrderButton} from '@components/UI/atoms/buttons/ButtonPrincipal';
import {orderSchema} from '@order/orderIncomplete/validationSchemas';
import {options} from '@order/orderIncomplete/data';
import OrderHeader from '@order/OrderHeader';
const OrderIncompleteGenesis = () => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const formatNumber = useFormatNumber();
    const {orderId} = useParams();
    const isMobile = useIsMobile();
    const token = useSelector((state: RootState) => state.logins.accessToken);
    const orderIncomplete = useSelector((state: RootState) => state.orderGenesis.orderIncomplete);
    const orderNumber = useSelector((state: RootState) => state.orderGenesis.orderId);
    const loading = useSelector((state: RootState) => state.orderGenesis.loading);
    const loadingpost = useSelector((state: RootState) => state.orderGenesis.loadingpost);
    //const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    // const focusInput = (index: number, textlen: number) => {
    //     if (inputRefs.current[index]) {
    //         inputRefs.current[index]?.focus();
    //         inputRefs.current[index]?.setSelectionRange(textlen, textlen);
    //     }
    // };

    // const onChangeAmount = (event: any, currenIndex: number) => {
    //     if (event === null) return;
    //     setValue(`lineItems[${currenIndex}].details`, event.target.value);
    //     setTimeout(() => focusInput(currenIndex, event.target.value.length), 100);
    // };

    useEffect(() => {
        if (orderIncomplete === null || orderId !== orderNumber) {
            navigate(`/genesis/order/${orderId}`);
            dispatch(resetOrderIncompleteSuccessGenesis());
        }
    }, [orderIncomplete, dispatch]);
    const {
        control,
        watch,
        handleSubmit,
        //formState: {errors},
        setValue,
    } = useForm({
        defaultValues: orderIncomplete,
        resolver: yupResolver(orderSchema),
        mode: 'onChange',
    });

    const onSubmitPatch = () => {
        const json = {
            billNumber: watchForm?.billNumber,
            lineItems: watchForm.lineItems.map((item: any, index: number) => {
                let cont;
                if (item.isOpenBatch) {
                    const cont = {
                        itemId: item.id,
                        details: item.details,
                        rejectedBy: item.rejectedBy,
                        batches: item.batches.map((items: any) => {
                            const conts = {
                                batch: items.batch === '' ? '00.00.0000' : items.batch,
                                received: Number(items.received),
                            };
                            return conts;
                        }),
                        store: item.store,
                    };
                    return cont;
                } else {
                    cont = {
                        itemId: item.id,
                        details: item.received === undefined ? '' : item.details || '',
                        rejectedBy:
                            item.received === undefined ? 'Pending Delivery' : item.rejectedBy,
                        batches:
                            item.received === undefined
                                ? [{received: 0, batch: item.batch}]
                                : [{received: Number(item.received), batch: item.batch}],
                        store: item.store,
                    };
                }
                return cont;
            }),
            postalcode: watchForm?.postalcode,
        };
        dispatch(fetchPostOrderGenesis(orderId, json, token));
    };
    const watchForm = watch();

    const goToBack = (e: any) => {
        e.preventDefault();
        navigate(`/genesis/order/${orderId}`);
    };

    const handleOpenMenu = (lineItemId: number) => {
        const isOpenMenu: boolean = watch(`lineItems[${lineItemId}].isOpenBatchDetails`);
        setValue(`lineItems[${lineItemId}].isOpenBatchDetails`, !isOpenMenu);
    };

    // const ItemDetailComp = (props: {
    //     index: number;
    //     item: any;
    //     receivedSum: any;
    //     receivedText: any;
    //     receivedDifference: any;
    // }) => {
    //     const {index, item, receivedSum, receivedText, receivedDifference} = props;
    //     return (
    //         <Box
    //             key={index}
    //             sx={{
    //                 marginTop: isMobile ? '36px' : '24px',
    //                 display: 'flex',
    //                 paddingBottom: isMobile ? '60px' : '30px',
    //                 flexDirection: 'column',
    //             }}
    //         >
    //             {item.isOpenBatch && (
    //                 <Box
    //                     sx={{
    //                         display: 'flex',
    //                         justifyContent: 'space-between',
    //                     }}
    //                 >
    //                     <Typography
    //                         sx={{
    //                             fontSize: isMobile ? '38px' : '25px',
    //                             fontFamily: 'Poppins',
    //                             fontWeight: 600,
    //                             color: '#000000',
    //                             opacity: 0.5,
    //                         }}
    //                     >
    //                         Ver detalles por lote{' '}
    //                     </Typography>
    //                     {Object.keys(watchForm).length > 0 && Array.isArray(watchForm.lineItems) ? (
    //                         watch(`lineItems[${index}].isOpenBatchDetails`) === true ? (
    //                             <img
    //                                 src={iconArrowDown}
    //                                 width="30px"
    //                                 onClick={() => handleOpenMenu(index)}
    //                             />
    //                         ) : (
    //                             <img
    //                                 src={iconArrowUp}
    //                                 width="30px"
    //                                 onClick={() => handleOpenMenu(index)}
    //                             />
    //                         )
    //                     ) : (
    //                         <img
    //                             src={iconArrowUp}
    //                             width="30px"
    //                             onClick={() => handleOpenMenu(index)}
    //                         />
    //                     )}
    //                 </Box>
    //             )}
    //             {Object.keys(watchForm).length > 0 && Array.isArray(watchForm.lineItems)
    //                 ? watch(`lineItems[${index}].isOpenBatchDetails`) === true && (
    //                       <>
    //                           {item.batches
    //                               .filter((items: any) => items !== null)
    //                               .map((items: any, index: number) => (
    //                                   <Box
    //                                       key={index}
    //                                       sx={{
    //                                           display: 'flex',
    //                                           paddingTop: '10px',
    //                                           marginTop: '10px',
    //                                           justifyContent: 'space-between',
    //                                       }}
    //                                   >
    //                                       <span>
    //                                           <span
    //                                               style={{
    //                                                   fontSize: isMobile ? '38px' : '25px',
    //                                                   fontFamily: 'Poppins',
    //                                                   fontWeight: 500,
    //                                                   color: '#000000',
    //                                                   opacity: 0.3,
    //                                               }}
    //                                           >
    //                                               Lote:
    //                                           </span>
    //                                           <span
    //                                               style={{
    //                                                   color: '#E5B611',
    //                                                   opacity: 1,
    //                                                   fontSize: isMobile ? '38px' : '25px',
    //                                                   fontWeight: 600,
    //                                                   fontFamily: 'Poppins',
    //                                               }}
    //                                           >
    //                                               {' ' + items.batch}
    //                                           </span>
    //                                       </span>
    //                                       <span>
    //                                           <span
    //                                               style={{
    //                                                   fontSize: isMobile ? '38px' : '25px',
    //                                                   fontFamily: 'Poppins',
    //                                                   fontWeight: 500,
    //                                                   color: '#000000',
    //                                                   opacity: 0.3,
    //                                               }}
    //                                           >
    //                                               Pzs por lote:
    //                                           </span>
    //                                           <span
    //                                               style={{
    //                                                   fontSize: isMobile ? '38px' : '25px',
    //                                                   fontFamily: 'Poppins',
    //                                                   fontWeight: 600,
    //                                                   color: '#000000',
    //                                                   opacity: 0.5,
    //                                               }}
    //                                           >
    //                                               {' ' + items.received}
    //                                           </span>
    //                                       </span>
    //                                   </Box>
    //                               ))}
    //                       </>
    //                   )
    //                 : null}
    //             <Box
    //                 sx={{
    //                     display: 'flex',
    //                     flexDirection: 'column',
    //                     borderBottom: '1px solid #0000001A',
    //                     paddingBottom: '10px',
    //                 }}
    //             >
    //                 <Box
    //                     sx={{
    //                         display: 'flex',
    //                         flexDirection: 'column',
    //                         width: '100%',
    //                         paddingTop: '10px',
    //                     }}
    //                 >
    //                     <Box
    //                         sx={{
    //                             display: 'flex',
    //                             alignItems: 'center',
    //                             margin: '10px 15px',
    //                             padding: isMobile ? '10px 0 10px 0' : '10px 0 0 0',
    //                             justifyContent: 'space-between',
    //                         }}
    //                     >
    //                         <Box
    //                             sx={{
    //                                 display: 'flex',
    //                                 flexDirection: 'column',
    //                             }}
    //                         >
    //                             <Typography
    //                                 sx={{
    //                                     fontSize: isMobile ? '38px' : '25px',
    //                                     fontFamily: 'Poppins',
    //                                     fontWeight: 600,
    //                                     color: '#011B34',
    //                                 }}
    //                             >
    //                                 <span style={{color: '#0781fe'}}>Pos. {item.position}</span> -{' '}
    //                                 {item.productName}
    //                             </Typography>
    //                             <Box
    //                                 sx={{
    //                                     display: 'flex',
    //                                 }}
    //                             >
    //                                 <Typography
    //                                     sx={{
    //                                         fontSize: isMobile ? '33.6px' : '22.4px',
    //                                         fontFamily: 'Poppins',
    //                                         fontWeight: 400,
    //                                         color: '#00000',
    //                                         opacity: 0.5,
    //                                     }}
    //                                 >
    //                                     Total{' '}
    //                                 </Typography>
    //                                 <Typography
    //                                     sx={{
    //                                         fontSize: isMobile ? '33.6px' : '22.4px',
    //                                         fontFamily: 'Poppins',
    //                                         fontWeight: 500,
    //                                         color: '#00ACFF',
    //                                         marginLeft: '10px',
    //                                     }}
    //                                 >
    //                                     {formatNumber(receivedSum * item.price)}
    //                                 </Typography>
    //                             </Box>
    //                         </Box>
    //                         <Typography
    //                             sx={{
    //                                 fontSize: isMobile ? '48px' : '32px',
    //                                 fontFamily: 'Poppins',
    //                                 fontWeight: 500,
    //                                 color: '#D1A33C',
    //                             }}
    //                         >
    //                             X{receivedSum}
    //                         </Typography>
    //                     </Box>
    //                     <Box
    //                         sx={{
    //                             display: 'flex',
    //                             alignItems: 'center',
    //                             margin: '0px 15px',
    //                             padding: '0px 0 25px 0',
    //                             justifyContent: 'space-between',
    //                         }}
    //                     >
    //                         <Typography
    //                             sx={{
    //                                 fontSize: isMobile ? '33.6px' : '22.4px',
    //                                 fontFamily: 'Poppins',
    //                                 fontWeight: 400,
    //                                 color: '#000000',
    //                                 opacity: 0.5,
    //                             }}
    //                         >
    //                             {receivedText}
    //                         </Typography>
    //                         <Controller
    //                             key={index}
    //                             name={`lineItems[${index}].differece`}
    //                             control={control}
    //                             render={({field, fieldState}) => {
    //                                 const color =
    //                                     Object.keys(watchForm).length > 0 &&
    //                                     Array.isArray(watchForm.lineItems)
    //                                         ? receivedSum === item.remaining
    //                                             ? '#4BBC68'
    //                                             : receivedSum > item.remaining
    //                                             ? '#325A6D'
    //                                             : receivedSum < item.remaining && receivedSum > 0
    //                                             ? '#E5B611'
    //                                             : '#C7C7C7'
    //                                         : '#C7C7C7';

    //                                 return (
    //                                     <TextField
    //                                         color="success"
    //                                         {...field}
    //                                         id={`totalReceived-${index}`}
    //                                         label="Total"
    //                                         value={receivedDifference}
    //                                         InputProps={{
    //                                             readOnly: true,
    //                                             endAdornment: (
    //                                                 <InputAdornment
    //                                                     position="end"
    //                                                     sx={{
    //                                                         color: '#000000',
    //                                                         fontWeight: 400,
    //                                                         fontFamily: 'Poppins',
    //                                                     }}
    //                                                 >
    //                                                     {item.unit}
    //                                                 </InputAdornment>
    //                                             ),
    //                                             sx: {
    //                                                 '& input': {
    //                                                     fontSize: isMobile ? '28px' : '19px',
    //                                                     textAlign: 'end',
    //                                                     color: color,
    //                                                 },
    //                                             },
    //                                         }}
    //                                         sx={{
    //                                             width: isMobile ? '250px' : '150px',
    //                                             border: color,
    //                                         }}
    //                                     />
    //                                 );
    //                             }}
    //                         />
    //                     </Box>
    //                 </Box>
    //                 <Box
    //                     sx={{
    //                         width: '100%',
    //                     }}
    //                 >
    //                     <Box
    //                         sx={{
    //                             display: 'flex',
    //                             flexDirection: 'column',
    //                         }}
    //                     >
    //                         <Controller
    //                             name={`lineItems[${index}].rejectedBy`}
    //                             control={control}
    //                             defaultValue={null}
    //                             render={({field, fieldState}) => (
    //                                 <FormControl
    //                                     fullWidth
    //                                     sx={{
    //                                         height: isMobile ? '100px' : '64px',
    //                                     }}
    //                                 >
    //                                     <InputLabel
    //                                         id="demo-simple-select-label"
    //                                         sx={{
    //                                             fontSize: isMobile ? '30px' : '20px',
    //                                             fontWeight: 400,
    //                                             color: '#011B34',
    //                                             fontFamily: 'Poppins',
    //                                             margin: '-5px 0 0 5px',
    //                                         }}
    //                                     >
    //                                         Motivo de rechazo
    //                                     </InputLabel>
    //                                     <Select
    //                                         id="demo-simple-select"
    //                                         label="Motivo de Rechazo"
    //                                         {...field}
    //                                         error={!!fieldState.error}
    //                                         sx={{
    //                                             fontSize: isMobile ? '30px' : '20px',
    //                                             height: isMobile ? '100px' : '64px',
    //                                             fontFamily: 'Poppins',
    //                                             fontWeight: 700,
    //                                             color: '#011B34',
    //                                             backgroundColor: '#fff',
    //                                         }}
    //                                     >
    //                                         {options.map((option, index) => (
    //                                             <MenuItem key={index} value={option.value}>
    //                                                 {option.label}
    //                                             </MenuItem>
    //                                         ))}
    //                                     </Select>
    //                                     <FormHelperText sx={{color: '#d32f2f'}}>
    //                                         {fieldState.error ? 'Motivo de rechazo requerido' : ''}
    //                                     </FormHelperText>
    //                                 </FormControl>
    //                             )}
    //                         />
    //                         <Controller
    //                             key={index}
    //                             name={`lineItems[${index}].diff`}
    //                             control={control}
    //                             defaultValue={item.diff}
    //                             render={({field, fieldState}) => <></>}
    //                         />
    //                         <Controller
    //                             name={`lineItems[${index}].details`}
    //                             control={control}
    //                             defaultValue={null}
    //                             render={({field, fieldState}) => (
    //                                 <TextField
    //                                     {...field}
    //                                     id={`batch-${index}`}
    //                                     label="Detalles adicionales"
    //                                     multiline
    //                                     minRows={3}
    //                                     maxRows={3}
    //                                     error={!!fieldState.error}
    //                                     helperText={
    //                                         fieldState.error ? 'Detalles adicionales requerido' : ''
    //                                     }
    //                                     sx={{
    //                                         '& .MuiInputBase-root': {
    //                                             fontSize: isMobile ? '30px' : '20px',
    //                                             fontFamily: 'Poppins',
    //                                             fontWeight: 500,
    //                                             color: '#011B34',
    //                                             marginTop: '20px',
    //                                             background: 'white',
    //                                         },
    //                                         '& .MuiInputLabel-root': {
    //                                             fontSize: isMobile ? '30px' : '20px',
    //                                             fontFamily: 'Poppins',
    //                                             fontWeight: 400,
    //                                             color: '#011B34',
    //                                             marginTop: '10px',
    //                                         },
    //                                         '& .MuiInputLabel-root.Mui-focused': {
    //                                             color: '#D1A33C',
    //                                         },
    //                                     }}
    //                                 />
    //                             )}
    //                         />
    //                     </Box>
    //                 </Box>
    //             </Box>
    //         </Box>
    //     );
    // };

    return (
        <>
            {loadingpost === true && <Loader text="Generando Hoja de entrada, espere un momento" />}
            <form onSubmit={handleSubmit(onSubmitPatch)}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    <OrderHeader isMobile={isMobile} logoAbi={logoAbi} />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'calc(100% - 70px)',
                            background: '#EDEFF2',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                background: '#fff',
                                padding: isMobile ? '60px 40px 52px 40px' : '30px 20%',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '45px' : '22px',
                                    fontFamily: 'Poppins',
                                    fontWeight: 600,
                                    color: '#011B34',
                                }}
                            >
                                Detalles de orden incompleta
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '33.6px' : '22.4px',
                                    fontFamily: 'Poppins',
                                    fontWeight: 400,
                                    color: '#011B34',
                                }}
                            >
                                Por favor, ayudanos con más detalle acerca de los productos que no
                                fueron marcados como completos
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                padding: isMobile ? '0 40px' : '0 20%',
                            }}
                        >
                            {orderIncomplete?.lineItems.map((item: any, index: number) => {
                                const {
                                    receivedSum,
                                    receivedDifferenceNumber,
                                    receivedDifference,
                                    receivedText,
                                } = useCalculateReceivedGenesis(item);

                                if (item.received) {
                                    if (receivedDifferenceNumber !== 0) {
                                        return (
                                            <Box
                                                key={index}
                                                sx={{
                                                    marginTop: isMobile ? '36px' : '24px',
                                                    display: 'flex',
                                                    paddingBottom: isMobile ? '60px' : '30px',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                {item.isOpenBatch && (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: isMobile
                                                                    ? '38px'
                                                                    : '25px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: 600,
                                                                color: '#000000',
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            Ver detalles por lote{' '}
                                                        </Typography>
                                                        {Object.keys(watchForm).length > 0 &&
                                                        Array.isArray(watchForm.lineItems) ? (
                                                            watch(
                                                                `lineItems[${index}].isOpenBatchDetails`
                                                            ) === true ? (
                                                                <img
                                                                    src={iconArrowDown}
                                                                    width="30px"
                                                                    onClick={() =>
                                                                        handleOpenMenu(index)
                                                                    }
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={iconArrowUp}
                                                                    width="30px"
                                                                    onClick={() =>
                                                                        handleOpenMenu(index)
                                                                    }
                                                                />
                                                            )
                                                        ) : (
                                                            <img
                                                                src={iconArrowUp}
                                                                width="30px"
                                                                onClick={() =>
                                                                    handleOpenMenu(index)
                                                                }
                                                            />
                                                        )}
                                                    </Box>
                                                )}
                                                {Object.keys(watchForm).length > 0 &&
                                                Array.isArray(watchForm.lineItems)
                                                    ? watch(
                                                          `lineItems[${index}].isOpenBatchDetails`
                                                      ) === true && (
                                                          <>
                                                              {item.batches
                                                                  .filter(
                                                                      (items: any) => items !== null
                                                                  )
                                                                  .map(
                                                                      (
                                                                          items: any,
                                                                          index: number
                                                                      ) => (
                                                                          <Box
                                                                              key={index}
                                                                              sx={{
                                                                                  display: 'flex',
                                                                                  paddingTop:
                                                                                      '10px',
                                                                                  marginTop: '10px',
                                                                                  justifyContent:
                                                                                      'space-between',
                                                                              }}
                                                                          >
                                                                              <span>
                                                                                  <span
                                                                                      style={{
                                                                                          fontSize:
                                                                                              isMobile
                                                                                                  ? '38px'
                                                                                                  : '25px',
                                                                                          fontFamily:
                                                                                              'Poppins',
                                                                                          fontWeight: 500,
                                                                                          color: '#000000',
                                                                                          opacity: 0.3,
                                                                                      }}
                                                                                  >
                                                                                      Lote:
                                                                                  </span>
                                                                                  <span
                                                                                      style={{
                                                                                          color: '#E5B611',
                                                                                          opacity: 1,
                                                                                          fontSize:
                                                                                              isMobile
                                                                                                  ? '38px'
                                                                                                  : '25px',
                                                                                          fontWeight: 600,
                                                                                          fontFamily:
                                                                                              'Poppins',
                                                                                      }}
                                                                                  >
                                                                                      {' ' +
                                                                                          items.batch}
                                                                                  </span>
                                                                              </span>
                                                                              <span>
                                                                                  <span
                                                                                      style={{
                                                                                          fontSize:
                                                                                              isMobile
                                                                                                  ? '38px'
                                                                                                  : '25px',
                                                                                          fontFamily:
                                                                                              'Poppins',
                                                                                          fontWeight: 500,
                                                                                          color: '#000000',
                                                                                          opacity: 0.3,
                                                                                      }}
                                                                                  >
                                                                                      Pzs por lote:
                                                                                  </span>
                                                                                  <span
                                                                                      style={{
                                                                                          fontSize:
                                                                                              isMobile
                                                                                                  ? '38px'
                                                                                                  : '25px',
                                                                                          fontFamily:
                                                                                              'Poppins',
                                                                                          fontWeight: 600,
                                                                                          color: '#000000',
                                                                                          opacity: 0.5,
                                                                                      }}
                                                                                  >
                                                                                      {' ' +
                                                                                          items.received}
                                                                                  </span>
                                                                              </span>
                                                                          </Box>
                                                                      )
                                                                  )}
                                                          </>
                                                      )
                                                    : null}
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderBottom: '1px solid #0000001A',
                                                        paddingBottom: '10px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            width: '100%',
                                                            paddingTop: '10px',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                margin: '10px 15px',
                                                                padding: isMobile
                                                                    ? '10px 0 10px 0'
                                                                    : '10px 0 0 0',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: isMobile
                                                                            ? '38px'
                                                                            : '25px',
                                                                        fontFamily: 'Poppins',
                                                                        fontWeight: 600,
                                                                        color: '#011B34',
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{color: '#0781fe'}}
                                                                    >
                                                                        Pos. {item.position}
                                                                    </span>{' '}
                                                                    - {item.productName}
                                                                </Typography>
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: isMobile
                                                                                ? '33.6px'
                                                                                : '22.4px',
                                                                            fontFamily: 'Poppins',
                                                                            fontWeight: 400,
                                                                            color: '#00000',
                                                                            opacity: 0.5,
                                                                        }}
                                                                    >
                                                                        Total{' '}
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: isMobile
                                                                                ? '33.6px'
                                                                                : '22.4px',
                                                                            fontFamily: 'Poppins',
                                                                            fontWeight: 500,
                                                                            color: '#00ACFF',
                                                                            marginLeft: '10px',
                                                                        }}
                                                                    >
                                                                        {formatNumber(
                                                                            receivedSum * item.price
                                                                        )}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: isMobile
                                                                        ? '48px'
                                                                        : '32px',
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: 500,
                                                                    color: '#D1A33C',
                                                                }}
                                                            >
                                                                X{receivedSum}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                margin: '0px 15px',
                                                                padding: '0px 0 25px 0',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: isMobile
                                                                        ? '33.6px'
                                                                        : '22.4px',
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: 400,
                                                                    color: '#000000',
                                                                    opacity: 0.5,
                                                                }}
                                                            >
                                                                {receivedText}
                                                            </Typography>
                                                            <Controller
                                                                key={index}
                                                                name={`lineItems[${index}].differece`}
                                                                control={control}
                                                                render={({field, fieldState}) => {
                                                                    const color =
                                                                        Object.keys(watchForm)
                                                                            .length > 0 &&
                                                                        Array.isArray(
                                                                            watchForm.lineItems
                                                                        )
                                                                            ? receivedSum ===
                                                                              item.remaining
                                                                                ? '#4BBC68'
                                                                                : receivedSum >
                                                                                  item.remaining
                                                                                ? '#325A6D'
                                                                                : receivedSum <
                                                                                      item.remaining &&
                                                                                  receivedSum > 0
                                                                                ? '#E5B611'
                                                                                : '#C7C7C7'
                                                                            : '#C7C7C7';

                                                                    return (
                                                                        <TextField
                                                                            color="success"
                                                                            {...field}
                                                                            id={`totalReceived-${index}`}
                                                                            label="Total"
                                                                            value={
                                                                                receivedDifference
                                                                            }
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                                endAdornment: (
                                                                                    <InputAdornment
                                                                                        position="end"
                                                                                        sx={{
                                                                                            color: '#000000',
                                                                                            fontWeight: 400,
                                                                                            fontFamily:
                                                                                                'Poppins',
                                                                                        }}
                                                                                    >
                                                                                        {item.unit}
                                                                                    </InputAdornment>
                                                                                ),
                                                                                sx: {
                                                                                    '& input': {
                                                                                        fontSize:
                                                                                            isMobile
                                                                                                ? '28px'
                                                                                                : '19px',
                                                                                        textAlign:
                                                                                            'end',
                                                                                        color: color,
                                                                                    },
                                                                                },
                                                                            }}
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '250px'
                                                                                    : '150px',
                                                                                border: color,
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                            }}
                                                        >
                                                            <Controller
                                                                name={`lineItems[${index}].rejectedBy`}
                                                                control={control}
                                                                defaultValue={null}
                                                                render={({field, fieldState}) => (
                                                                    <FormControl
                                                                        fullWidth
                                                                        sx={{
                                                                            height: isMobile
                                                                                ? '100px'
                                                                                : '64px',
                                                                        }}
                                                                    >
                                                                        <InputLabel
                                                                            id="demo-simple-select-label"
                                                                            sx={{
                                                                                fontSize: isMobile
                                                                                    ? '30px'
                                                                                    : '20px',
                                                                                fontWeight: 400,
                                                                                color: '#011B34',
                                                                                fontFamily:
                                                                                    'Poppins',
                                                                                margin: '-5px 0 0 5px',
                                                                            }}
                                                                        >
                                                                            Motivo de rechazo
                                                                        </InputLabel>
                                                                        <Select
                                                                            id="demo-simple-select"
                                                                            label="Motivo de Rechazo"
                                                                            {...field}
                                                                            error={
                                                                                !!fieldState.error
                                                                            }
                                                                            sx={{
                                                                                fontSize: isMobile
                                                                                    ? '30px'
                                                                                    : '20px',
                                                                                height: isMobile
                                                                                    ? '100px'
                                                                                    : '64px',
                                                                                fontFamily:
                                                                                    'Poppins',
                                                                                fontWeight: 700,
                                                                                color: '#011B34',
                                                                                backgroundColor:
                                                                                    '#fff',
                                                                            }}
                                                                        >
                                                                            {options.map(
                                                                                (option, index) => (
                                                                                    <MenuItem
                                                                                        key={index}
                                                                                        value={
                                                                                            option.value
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            option.label
                                                                                        }
                                                                                    </MenuItem>
                                                                                )
                                                                            )}
                                                                        </Select>
                                                                        <FormHelperText
                                                                            sx={{color: '#d32f2f'}}
                                                                        >
                                                                            {fieldState.error
                                                                                ? 'Motivo de rechazo requerido'
                                                                                : ''}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                )}
                                                            />
                                                            <Controller
                                                                key={index}
                                                                name={`lineItems[${index}].diff`}
                                                                control={control}
                                                                defaultValue={item.diff}
                                                                render={({field, fieldState}) => (
                                                                    <></>
                                                                )}
                                                            />
                                                            <Controller
                                                                name={`lineItems[${index}].details`}
                                                                control={control}
                                                                defaultValue={null}
                                                                render={({field, fieldState}) => (
                                                                    <TextField
                                                                        {...field}
                                                                        id={`batch-${index}`}
                                                                        label="Detalles adicionales"
                                                                        multiline
                                                                        minRows={3}
                                                                        maxRows={3}
                                                                        error={!!fieldState.error}
                                                                        helperText={
                                                                            fieldState.error
                                                                                ? 'Detalles adicionales requerido'
                                                                                : ''
                                                                        }
                                                                        sx={{
                                                                            '& .MuiInputBase-root':
                                                                                {
                                                                                    fontSize:
                                                                                        isMobile
                                                                                            ? '30px'
                                                                                            : '20px',
                                                                                    fontFamily:
                                                                                        'Poppins',
                                                                                    fontWeight: 500,
                                                                                    color: '#011B34',
                                                                                    marginTop:
                                                                                        '20px',
                                                                                    background:
                                                                                        'white',
                                                                                },
                                                                            '& .MuiInputLabel-root':
                                                                                {
                                                                                    fontSize:
                                                                                        isMobile
                                                                                            ? '30px'
                                                                                            : '20px',
                                                                                    fontFamily:
                                                                                        'Poppins',
                                                                                    fontWeight: 400,
                                                                                    color: '#011B34',
                                                                                    marginTop:
                                                                                        '10px',
                                                                                },
                                                                            '& .MuiInputLabel-root.Mui-focused':
                                                                                {
                                                                                    color: '#D1A33C',
                                                                                },
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        );
                                    }
                                    return null;
                                } else {
                                    if (item.isOpenBatch && receivedDifferenceNumber !== 0) {
                                        return (
                                            <Box
                                                key={index}
                                                sx={{
                                                    marginTop: isMobile ? '36px' : '24px',
                                                    display: 'flex',
                                                    paddingBottom: isMobile ? '60px' : '30px',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                {item.isOpenBatch && (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: isMobile
                                                                    ? '38px'
                                                                    : '25px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: 600,
                                                                color: '#000000',
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            Ver detalles por lote{' '}
                                                        </Typography>
                                                        {Object.keys(watchForm).length > 0 &&
                                                        Array.isArray(watchForm.lineItems) ? (
                                                            watch(
                                                                `lineItems[${index}].isOpenBatchDetails`
                                                            ) === true ? (
                                                                <img
                                                                    src={iconArrowDown}
                                                                    width="30px"
                                                                    onClick={() =>
                                                                        handleOpenMenu(index)
                                                                    }
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={iconArrowUp}
                                                                    width="30px"
                                                                    onClick={() =>
                                                                        handleOpenMenu(index)
                                                                    }
                                                                />
                                                            )
                                                        ) : (
                                                            <img
                                                                src={iconArrowUp}
                                                                width="30px"
                                                                onClick={() =>
                                                                    handleOpenMenu(index)
                                                                }
                                                            />
                                                        )}
                                                    </Box>
                                                )}
                                                {Object.keys(watchForm).length > 0 &&
                                                Array.isArray(watchForm.lineItems)
                                                    ? watch(
                                                          `lineItems[${index}].isOpenBatchDetails`
                                                      ) === true && (
                                                          <>
                                                              {item.batches
                                                                  .filter(
                                                                      (items: any) => items !== null
                                                                  )
                                                                  .map(
                                                                      (
                                                                          items: any,
                                                                          index: number
                                                                      ) => (
                                                                          <Box
                                                                              key={index}
                                                                              sx={{
                                                                                  display: 'flex',
                                                                                  paddingTop:
                                                                                      '10px',
                                                                                  marginTop: '10px',
                                                                                  justifyContent:
                                                                                      'space-between',
                                                                              }}
                                                                          >
                                                                              <span>
                                                                                  <span
                                                                                      style={{
                                                                                          fontSize:
                                                                                              isMobile
                                                                                                  ? '38px'
                                                                                                  : '25px',
                                                                                          fontFamily:
                                                                                              'Poppins',
                                                                                          fontWeight: 500,
                                                                                          color: '#000000',
                                                                                          opacity: 0.3,
                                                                                      }}
                                                                                  >
                                                                                      Lote:
                                                                                  </span>
                                                                                  <span
                                                                                      style={{
                                                                                          color: '#E5B611',
                                                                                          opacity: 1,
                                                                                          fontSize:
                                                                                              isMobile
                                                                                                  ? '38px'
                                                                                                  : '25px',
                                                                                          fontWeight: 600,
                                                                                          fontFamily:
                                                                                              'Poppins',
                                                                                      }}
                                                                                  >
                                                                                      {' ' +
                                                                                          items.batch}
                                                                                  </span>
                                                                              </span>
                                                                              <span>
                                                                                  <span
                                                                                      style={{
                                                                                          fontSize:
                                                                                              isMobile
                                                                                                  ? '38px'
                                                                                                  : '25px',
                                                                                          fontFamily:
                                                                                              'Poppins',
                                                                                          fontWeight: 500,
                                                                                          color: '#000000',
                                                                                          opacity: 0.3,
                                                                                      }}
                                                                                  >
                                                                                      Pzs por lote:
                                                                                  </span>
                                                                                  <span
                                                                                      style={{
                                                                                          fontSize:
                                                                                              isMobile
                                                                                                  ? '38px'
                                                                                                  : '25px',
                                                                                          fontFamily:
                                                                                              'Poppins',
                                                                                          fontWeight: 600,
                                                                                          color: '#000000',
                                                                                          opacity: 0.5,
                                                                                      }}
                                                                                  >
                                                                                      {' ' +
                                                                                          items.received}
                                                                                  </span>
                                                                              </span>
                                                                          </Box>
                                                                      )
                                                                  )}
                                                          </>
                                                      )
                                                    : null}
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        borderBottom: '1px solid #0000001A',
                                                        paddingBottom: '10px',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            width: '100%',
                                                            paddingTop: '10px',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                margin: '10px 15px',
                                                                padding: isMobile
                                                                    ? '10px 0 10px 0'
                                                                    : '10px 0 0 0',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: isMobile
                                                                            ? '38px'
                                                                            : '25px',
                                                                        fontFamily: 'Poppins',
                                                                        fontWeight: 600,
                                                                        color: '#011B34',
                                                                    }}
                                                                >
                                                                    <span
                                                                        style={{color: '#0781fe'}}
                                                                    >
                                                                        Pos. {item.position}
                                                                    </span>{' '}
                                                                    - {item.productName}
                                                                </Typography>
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: isMobile
                                                                                ? '33.6px'
                                                                                : '22.4px',
                                                                            fontFamily: 'Poppins',
                                                                            fontWeight: 400,
                                                                            color: '#00000',
                                                                            opacity: 0.5,
                                                                        }}
                                                                    >
                                                                        Total{' '}
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: isMobile
                                                                                ? '33.6px'
                                                                                : '22.4px',
                                                                            fontFamily: 'Poppins',
                                                                            fontWeight: 500,
                                                                            color: '#00ACFF',
                                                                            marginLeft: '10px',
                                                                        }}
                                                                    >
                                                                        {formatNumber(
                                                                            receivedSum * item.price
                                                                        )}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: isMobile
                                                                        ? '48px'
                                                                        : '32px',
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: 500,
                                                                    color: '#D1A33C',
                                                                }}
                                                            >
                                                                X{receivedSum}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                margin: '0px 15px',
                                                                padding: '0px 0 25px 0',
                                                                justifyContent: 'space-between',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: isMobile
                                                                        ? '33.6px'
                                                                        : '22.4px',
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: 400,
                                                                    color: '#000000',
                                                                    opacity: 0.5,
                                                                }}
                                                            >
                                                                {receivedText}
                                                            </Typography>
                                                            <Controller
                                                                key={index}
                                                                name={`lineItems[${index}].differece`}
                                                                control={control}
                                                                render={({field, fieldState}) => {
                                                                    const color =
                                                                        Object.keys(watchForm)
                                                                            .length > 0 &&
                                                                        Array.isArray(
                                                                            watchForm.lineItems
                                                                        )
                                                                            ? receivedSum ===
                                                                              item.remaining
                                                                                ? '#4BBC68'
                                                                                : receivedSum >
                                                                                  item.remaining
                                                                                ? '#325A6D'
                                                                                : receivedSum <
                                                                                      item.remaining &&
                                                                                  receivedSum > 0
                                                                                ? '#E5B611'
                                                                                : '#C7C7C7'
                                                                            : '#C7C7C7';

                                                                    return (
                                                                        <TextField
                                                                            color="success"
                                                                            {...field}
                                                                            id={`totalReceived-${index}`}
                                                                            label="Total"
                                                                            value={
                                                                                receivedDifference
                                                                            }
                                                                            InputProps={{
                                                                                readOnly: true,
                                                                                endAdornment: (
                                                                                    <InputAdornment
                                                                                        position="end"
                                                                                        sx={{
                                                                                            color: '#000000',
                                                                                            fontWeight: 400,
                                                                                            fontFamily:
                                                                                                'Poppins',
                                                                                        }}
                                                                                    >
                                                                                        {item.unit}
                                                                                    </InputAdornment>
                                                                                ),
                                                                                sx: {
                                                                                    '& input': {
                                                                                        fontSize:
                                                                                            isMobile
                                                                                                ? '28px'
                                                                                                : '19px',
                                                                                        textAlign:
                                                                                            'end',
                                                                                        color: color,
                                                                                    },
                                                                                },
                                                                            }}
                                                                            sx={{
                                                                                width: isMobile
                                                                                    ? '250px'
                                                                                    : '150px',
                                                                                border: color,
                                                                            }}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                            }}
                                                        >
                                                            <Controller
                                                                name={`lineItems[${index}].rejectedBy`}
                                                                control={control}
                                                                defaultValue={null}
                                                                render={({field, fieldState}) => (
                                                                    <FormControl
                                                                        fullWidth
                                                                        sx={{
                                                                            height: isMobile
                                                                                ? '100px'
                                                                                : '64px',
                                                                        }}
                                                                    >
                                                                        <InputLabel
                                                                            id="demo-simple-select-label"
                                                                            sx={{
                                                                                fontSize: isMobile
                                                                                    ? '30px'
                                                                                    : '20px',
                                                                                fontWeight: 400,
                                                                                color: '#011B34',
                                                                                fontFamily:
                                                                                    'Poppins',
                                                                                margin: '-5px 0 0 5px',
                                                                            }}
                                                                        >
                                                                            Motivo de rechazo
                                                                        </InputLabel>
                                                                        <Select
                                                                            id="demo-simple-select"
                                                                            label="Motivo de Rechazo"
                                                                            {...field}
                                                                            error={
                                                                                !!fieldState.error
                                                                            }
                                                                            sx={{
                                                                                fontSize: isMobile
                                                                                    ? '30px'
                                                                                    : '20px',
                                                                                height: isMobile
                                                                                    ? '100px'
                                                                                    : '64px',
                                                                                fontFamily:
                                                                                    'Poppins',
                                                                                fontWeight: 700,
                                                                                color: '#011B34',
                                                                                backgroundColor:
                                                                                    '#fff',
                                                                            }}
                                                                        >
                                                                            {options.map(
                                                                                (option, index) => (
                                                                                    <MenuItem
                                                                                        key={index}
                                                                                        value={
                                                                                            option.value
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            option.label
                                                                                        }
                                                                                    </MenuItem>
                                                                                )
                                                                            )}
                                                                        </Select>
                                                                        <FormHelperText
                                                                            sx={{color: '#d32f2f'}}
                                                                        >
                                                                            {fieldState.error
                                                                                ? 'Motivo de rechazo requerido'
                                                                                : ''}
                                                                        </FormHelperText>
                                                                    </FormControl>
                                                                )}
                                                            />
                                                            <Controller
                                                                key={index}
                                                                name={`lineItems[${index}].diff`}
                                                                control={control}
                                                                defaultValue={item.diff}
                                                                render={({field, fieldState}) => (
                                                                    <></>
                                                                )}
                                                            />
                                                            <Controller
                                                                name={`lineItems[${index}].details`}
                                                                control={control}
                                                                defaultValue={null}
                                                                render={({field, fieldState}) => (
                                                                    <TextField
                                                                        {...field}
                                                                        id={`batch-${index}`}
                                                                        label="Detalles adicionales"
                                                                        multiline
                                                                        minRows={3}
                                                                        maxRows={3}
                                                                        error={!!fieldState.error}
                                                                        helperText={
                                                                            fieldState.error
                                                                                ? 'Detalles adicionales requerido'
                                                                                : ''
                                                                        }
                                                                        sx={{
                                                                            '& .MuiInputBase-root':
                                                                                {
                                                                                    fontSize:
                                                                                        isMobile
                                                                                            ? '30px'
                                                                                            : '20px',
                                                                                    fontFamily:
                                                                                        'Poppins',
                                                                                    fontWeight: 500,
                                                                                    color: '#011B34',
                                                                                    marginTop:
                                                                                        '20px',
                                                                                    background:
                                                                                        'white',
                                                                                },
                                                                            '& .MuiInputLabel-root':
                                                                                {
                                                                                    fontSize:
                                                                                        isMobile
                                                                                            ? '30px'
                                                                                            : '20px',
                                                                                    fontFamily:
                                                                                        'Poppins',
                                                                                    fontWeight: 400,
                                                                                    color: '#011B34',
                                                                                    marginTop:
                                                                                        '10px',
                                                                                },
                                                                            '& .MuiInputLabel-root.Mui-focused':
                                                                                {
                                                                                    color: '#D1A33C',
                                                                                },
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        );
                                    } else return null;
                                }
                            })}
                            {orderIncomplete?.lineItems.filter(
                                (item: any) => item.received === undefined && !item.isOpenBatch
                            ).length > 0 && (
                                <Box>
                                    <Typography
                                        sx={{
                                            fontSize: isMobile ? '45px' : '22px',
                                            fontFamily: 'Poppins',
                                            fontWeight: 600,
                                            color: '#D1A33C',
                                        }}
                                    >
                                        Pendientes de entrega:
                                    </Typography>
                                    {orderIncomplete?.lineItems
                                        .filter(
                                            (item: any) =>
                                                item.received === undefined && !item.isOpenBatch
                                        )
                                        .map((item: any, index: number) => (
                                            <Box
                                                key={index}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '100%',
                                                    paddingTop: '10px',
                                                    borderBottom: '1px solid #0000001A',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        margin: '10px 15px',
                                                        padding: isMobile
                                                            ? '10px 0 10px 0'
                                                            : '10px 0 0 0',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: isMobile
                                                                    ? '38px'
                                                                    : '25px',
                                                                fontFamily: 'Poppins',
                                                                fontWeight: 600,
                                                                color: '#011B34',
                                                            }}
                                                        >
                                                            <span style={{color: '#0781fe'}}>
                                                                Pos. {item.position}
                                                            </span>{' '}
                                                            - {item.productName}
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: isMobile
                                                                        ? '33.6px'
                                                                        : '22.4px',
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: 400,
                                                                    color: '#00000',
                                                                    opacity: 0.5,
                                                                }}
                                                            >
                                                                Total{' '}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    fontSize: isMobile
                                                                        ? '33.6px'
                                                                        : '22.4px',
                                                                    fontFamily: 'Poppins',
                                                                    fontWeight: 500,
                                                                    color: '#00ACFF',
                                                                    marginLeft: '10px',
                                                                }}
                                                            >
                                                                {formatNumber(
                                                                    item.price * item.remaining
                                                                )}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Typography
                                                        sx={{
                                                            fontSize: isMobile ? '48px' : '32px',
                                                            fontFamily: 'Poppins',
                                                            fontWeight: 500,
                                                            color: '#D1A33C',
                                                        }}
                                                    >
                                                        X{item.remaining}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        ))}
                                </Box>
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    borderTop: '1px solid #011B34',
                                    alignItems: 'center',
                                    marginTop: '20px',
                                }}
                            >
                                <svg
                                    width="40"
                                    height="40"
                                    viewBox="0 0 20 19"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9.72111 18.8844C14.8303 18.8844 19.0106 14.7041 19.0106 9.5949C19.0106 4.48568 14.8303 0.30542 9.72112 0.30542C4.61191 0.305419 0.431641 4.48568 0.431641 9.59489C0.43164 14.7041 4.6119 18.8844 9.72111 18.8844ZM9.72112 4.95016C10.2785 4.95016 10.6501 5.32174 10.6501 5.8791C10.6501 6.43647 10.2785 6.80805 9.72112 6.80805C9.16375 6.80805 8.79217 6.43647 8.79217 5.8791C8.79217 5.32174 9.16375 4.95016 9.72112 4.95016ZM8.79217 9.59489C8.79217 9.03753 9.16375 8.66595 9.72112 8.66595C10.2785 8.66595 10.6501 9.03753 10.6501 9.59489V13.3107C10.6501 13.8681 10.2785 14.2396 9.72112 14.2396C9.16375 14.2396 8.79217 13.8681 8.79217 13.3107V9.59489Z"
                                        fill="#1890FF"
                                    />
                                </svg>
                                <Typography
                                    sx={{
                                        fontSize: isMobile ? '36px' : '24px',
                                        fontFamily: 'Poppins',
                                        fontWeight: 400,
                                        color: '#011B34',
                                        paddingTop: '20px',
                                        paddingLeft: '20px',
                                    }}
                                >
                                    Verifica que toda la información sea correcta, al igual que el
                                    producto sea el correcto{' '}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '20px',
                            paddingX: '20px',
                        }}
                    >
                        <BackButton onClick={(e) => goToBack(e)}>Volver</BackButton>
                        <OrderButton type="submit" disabled={loadingpost}>
                            {loading || loadingpost ? 'Cargando...' : 'Confirmar'}
                        </OrderButton>
                    </Box>
                </Box>
            </form>
        </>
    );
};
export default OrderIncompleteGenesis;
