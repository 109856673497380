import React from 'react';

import {Box, Grid, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';

import {ButtonCancel, Nav, StyledModal} from '@/components/faqs/StyledFAQ';
import StatusTextGenecic from '@/components/UI/atoms/buttons/StatusTextGeneric';
import {DateTime} from 'luxon';
import {useTranslation} from 'react-i18next';
//import {IInvoiceMap} from '@/components/orderSimply/interfaces/invoice-map.interface';

interface ModalProps {
    isModalOpen: boolean;
    closeModal: any;
    item: any;
    poType: string;
}

const sortFn = (item1: any, item2: any) => {
    return new Date(item2.date).getTime() - new Date(item1.date).getTime();
};

const ModalClientExternal: React.FC<ModalProps> = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const {isModalOpen, closeModal, item} = props;

    const collect = JSON.parse(JSON.stringify(item));

    let totalPaidAmount = 0;
    // TODO: Check this logic, I'll maintain it in order to have a fallback.
    // const total =
    //     item && item.bills
    //         ? item.bills.reduce((carry: number, bill: any) => Number(bill.amount) + carry, 0)
    //         : 0;
    return (
        <StyledModal open={isModalOpen} onClose={closeModal}>
            <Box
                sx={{
                    background: '#FFF',
                    display: 'flex',
                    flexDirection: 'column',
                    borderRadius: '10px',
                    width: '40vw',
                }}
            >
                <Nav>
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontFamily: theme.typography.fontFamily,
                            fontWeight: 700,
                            color: 'white',
                            marginLeft: '10px',
                        }}
                    >
                        {item && (
                            <span>{`${t('confirmposimply.bill_detail_modal.details_order')} #${
                                item.external_id
                            }`}</span>
                        )}
                    </Typography>
                </Nav>
                <Box sx={{padding: '20px'}}>
                    <Grid xs={12} item container sx={{marginBottom: '10px'}}>
                        <Grid
                            xs={12}
                            item
                            container
                            sx={{
                                background: '#e9e6e6',
                                padding: '4px 2px 4px 2px',
                            }}
                        >
                            <Grid item xs={3}>
                                <span style={{fontWeight: 'bold'}}>
                                    {t('confirmposimply.bill_detail_modal.bill_reference')}
                                </span>
                            </Grid>
                            <Grid item xs={3}>
                                <span style={{fontWeight: 'bold'}}>
                                    {t('confirmposimply.bill_detail_modal.bill_date')}
                                </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span style={{fontWeight: 'bold'}}>
                                    {t('consultpo.table.headers.status')}
                                </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span style={{fontWeight: 'bold'}}>
                                    {t('confirmposimply.bill_detail_modal.amount')}
                                </span>
                            </Grid>
                            <Grid item xs={2}>
                                <span style={{fontWeight: 'bold'}}>{t('ticket.pay_date')}</span>
                            </Grid>
                        </Grid>
                        <Grid
                            sx={{
                                height: '380px',
                                overflowY: 'scroll',
                                overflowX: 'hidden',
                                width: '100%',
                            }}
                        >
                            {collect &&
                                collect.bills.sort(sortFn).map((bill: any, index: number) => {
                                    //let mappedPayment: IInvoiceMap = {amount: '0', lineItemId: ''};
                                    if (bill.invoice_map && bill.invoice_map.length > 0) {
                                        // mappedPayment = bill.invoice_map.find(
                                        //     (invoice: IInvoiceMap) =>
                                        //         invoice.lineItemId === item.line_item_id
                                        // );
                                    } else if (bill.positions && bill.positions.length > 0) {
                                        // mappedPayment = bill.positions.find(
                                        //     (position: any) =>
                                        //         position.position === String(item.position)
                                        //);
                                    } else {
                                        // Default value as previous implementation.
                                        // TODO: Check this logic, I'll maintain it in order to have a fallback.
                                        // mappedPayment = {amount: total, lineItemId: ''};
                                    }
                                    if (bill.status !== 'REJECTED') {
                                        totalPaidAmount += Number(bill.amount);
                                    }
                                    return (
                                        <Grid container key={index} sx={{marginTop: '10px'}}>
                                            <Grid item xs={3}>
                                                <span
                                                    style={{
                                                        padding: '4px 2px 4px 2px',
                                                    }}
                                                >
                                                    {bill.reference}
                                                </span>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <span
                                                    style={{
                                                        padding: '4px 2px 4px 2px',
                                                    }}
                                                >
                                                    {bill?.date
                                                        ? DateTime.fromISO(bill.date).toFormat(
                                                              'dd/MM/yyyy'
                                                          )
                                                        : '-'}
                                                </span>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <StatusTextGenecic
                                                    text={t(
                                                        `confirmposimply.bill_detail_modal.states.${bill.status}`
                                                    )}
                                                    status={bill.status}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <span
                                                    style={{
                                                        padding: '4px 2px 4px 2px',
                                                    }}
                                                >
                                                    {Number(bill.amount).toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                    })}
                                                </span>
                                            </Grid>
                                            <Grid item xs={2}>
                                                {bill?.payment_date
                                                    ? DateTime.fromISO(bill.payment_date).toFormat(
                                                          'dd/MM/yyyy'
                                                      )
                                                    : '-'}
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                        </Grid>

                        <Grid xs={12} item container sx={{marginTop: '10px'}}>
                            <Grid
                                xs={12}
                                item
                                container
                                sx={{
                                    background: '#e9e6e6',
                                    padding: '4px 2px 4px 2px',
                                }}
                            >
                                <Grid item xs={4}>
                                    {''}
                                </Grid>
                                <Grid item xs={4} sx={{textAlign: 'right'}}>
                                    <span style={{fontWeight: 'bold', marginRight: '10px'}}>
                                        Total
                                    </span>
                                </Grid>
                                <Grid item xs={4}>
                                    <span style={{fontWeight: 'bold'}}>
                                        {totalPaidAmount.toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}
                                    </span>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        height: '56px',
                        alignItems: 'center',
                        margin: '20px',
                        justifyContent: 'flex-end',
                    }}
                >
                    <ButtonCancel onClick={closeModal}>{t('close')}</ButtonCancel>
                </Box>
            </Box>
        </StyledModal>
    );
};

export default React.memo(ModalClientExternal);
