import {
    GET_ORDER_CONFIRM,
    GET_ORDER_CONFIRM_SUCCESS,
    GET_ORDER_CONFIRM_ERROR,
    SET_ORDER_CONFIRM_STATUS,
    SET_ORDER_CONFIRM_STATUS_SUCCESS,
    SET_ORDER_CONFIRM_STATUS_ERROR,
    GET_DOWNLOAD_ORDER_CONFIRM,
    GET_DOWNLOAD_ORDER_CONFIRM_SUCCESS,
    GET_DOWNLOAD_ORDER_CONFIRM_ERROR,
} from '@components/orderConfirm/redux/types/OrderConfirmTypes';

import axios from 'axios';

export const getOrderConfirm = () => {
    return {
        type: GET_ORDER_CONFIRM,
    };
};

export const getOrderConfirmSuccess = (result, meta) => {
    return {
        type: GET_ORDER_CONFIRM_SUCCESS,
        value: result,
        metadata: meta,
    };
};

export const getOrderConfirmError = () => {
    return {
        type: GET_ORDER_CONFIRM_ERROR,
    };
};

export function getUrls(item) {
    if (!item.entries || item.entries.length === 0) return [];
    const bills = item.entries.flatMap((entry) => entry.bills);

    if (bills.length === 0) return [];

    const attachments = bills.flatMap((bill) => bill.billAttachments);

    if (attachments.length === 0) return [];

    const urls = attachments.map((att) => att.url);

    return urls;
}

const getNetImport = (item) => {
    const hasEntryForeignBills = item.entries.some((e) =>
        e.bills.some((bill) => bill.foreignInvoice !== null)
    );

    if (!item.bill && hasEntryForeignBills) {
        const total = item.entries
            .flatMap((item) => item.bills)
            .reduce((carry, item) => item.foreignInvoice?.amount + carry, 0);
        return total.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 2,
        });
    }
    return (
        item.remaining * item.productDetails[0].price +
        Number(
            item.taxes.reduce((acc, tax) => {
                return acc + Number(tax.value);
            }, 0)
        )
    );
};
export function fetchGetOrderConfirm(token, filters = {}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = filters;
        dispatch(getOrderConfirm());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/purchaser-order/foreign/list/all`,
                {
                    headers: headers,
                    params: params,
                }
            );
            const newArray = response.data.data
                .map((po) => {
                    return po.lineItems.flatMap((item) => {
                        const isBillApproved =
                            item.bill?.validations[0]?.validationStatus === 'APPROVED';
                        const lineItemIsValidated = item.bill !== null && isBillApproved;
                        const isBillValidated =
                            po.status === 'BILL_VALIDATED' && lineItemIsValidated;
                        const bills = item.entries
                            .flatMap((entry) => entry && entry.bills)
                            .filter((bill) => bill.foreignInvoice);
                        return {
                            id: po.id,
                            orderId: po.referenceId,
                            externalId: po.buyerBranchOffice.externalId,
                            societyName: po.buyerBranchOffice.name,
                            billReference: item.billingRef,
                            billValidated: isBillValidated,
                            textDescription:
                                item.productDetails[0].productName +
                                (item.productDetails[0].description !== null
                                    ? item.productDetails[0].description
                                    : ''),
                            billAmount: getNetImport(item),
                            currency: bills[0]?.foreignInvoice.currency,
                            sku: item.productDetails[0].sku,
                            billingDate: item.billingDate,
                            pos: item.position,
                            priceUnit: item.productDetails[0].price,
                            paymentDate: item.paymentDate,
                            productName: item.productDetails[0].productName,
                            entrySheet: item.entrySheet ? item.entrySheet : item.entries[0].number,
                            orderFindId: po.id,
                            companyGroup: po.buyerCompany.companyGroup,
                            remaining: item.remaining,
                            iterations: po.iterations,
                            lineItemId: item.id,
                            message: item.details,
                            materialType: po.materialType,
                            websiteType: po.vendorCompany.websiteType,
                            urls: getUrls(item),
                            itemStatus: item.status,
                            invoiceDate: item.entries[0].bills[0].foreignInvoice.invoiceDate,
                            invoiceReference: item.entries[0].bills[0].foreignInvoice.invoiceNumber,
                        };
                    });
                })
                .flat();
            const pos = filters.limit * (filters.page - 1);
            const finalData = newArray.slice(pos, pos + filters.limit);
            dispatch(getOrderConfirmSuccess(finalData, response.data.metadata));
        } catch (error) {
            dispatch(getOrderConfirmError());
        }
    };
}

export const setOrderConfirmStatus = () => {
    return {
        type: SET_ORDER_CONFIRM_STATUS,
    };
};

export const setOrderConfirmStatusSuccess = (result) => {
    return {
        type: SET_ORDER_CONFIRM_STATUS_SUCCESS,
        value: result,
    };
};

export const setOrderConfirmStatusError = () => {
    return {
        type: SET_ORDER_CONFIRM_STATUS_ERROR,
    };
};

export function fetchPutStatusOrderConfirm(token, order, action, rejectedReason) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(setOrderConfirmStatus());
        try {
            const data = {
                status: action,
                lineItemId: order.lineItemId,
                ...(rejectedReason && {rejectedReason}),
            };
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/v2/purchaser-order/${order.id}/foreign`,
                data,
                {headers}
            );
            dispatch(setOrderConfirmStatusSuccess(response.data));
        } catch (error) {
            dispatch(setOrderConfirmStatusError());
        }
    };
}

export const getOrderConfirmDownload = () => {
    return {
        type: GET_DOWNLOAD_ORDER_CONFIRM,
    };
};

export const getOrderConfirmDownloadSuccess = () => {
    return {
        type: GET_DOWNLOAD_ORDER_CONFIRM_SUCCESS,
    };
};

export const getOrderConfirmDownloadError = () => {
    return {
        type: GET_DOWNLOAD_ORDER_CONFIRM_ERROR,
    };
};

export function fetchGetDownloadOrderConfirms(token, format, filters, status) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        let params = filters;
        dispatch(getOrderConfirmDownload());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}/v2/enterprise/download/${format}/${status}`,
                {
                    headers: headers,
                    params: params,
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));

            const a = document.createElement('a');
            a.href = url;
            a.download = `candidatos.${format}`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            dispatch(getOrderConfirmDownloadSuccess());
        } catch (error) {
            dispatch(getOrderConfirmDownloadError());
        }
    };
}
